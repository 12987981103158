import React from "react";
import PropTypes from "prop-types";

export default ({ children }) => (
  <>
    <main>{children}</main>
    <footer className="is-white-bg is-grey margin-2 footer">
      Site made with{" "}
      <span role="img" aria-label="love">
        ❤️
      </span>{" "}
      by{" "}
      <a
        href="https://sld.codes"
        target="_blank"
        rel="noopener noreferrer"
        className="link is-blue"
      >
        sld.codes
      </a>
      <a
        className="link is-blue"
        href="https://www.buymeacoffee.com/lLj8yvRAk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <p>
          Buy me a coffee{" "}
          <span role="img" aria-label="coffee">
            ☕️
          </span>
          .
        </p>
      </a>
    </footer>
  </>
);
